import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ContactForm from 'components/contact-form'
import { FaSearch} from "@react-icons/all-files/fa/FaSearch";
import { Helmet } from 'react-helmet'
import OGImage from '../../../content/images/logo.png'

interface Props {
  location: Location
}

const FAQPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_img = data.hero_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Helmet>
        <link rel="canonical" href="https://meridianendoindy.com/patient-information/endodontic-faq/"/>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <title>Endodontic FAQ endodontics pc, Indianapolis IN</title>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Endodontic FAQ endodontics pc, Indianapolis IN"/>
        <meta name="description" content="Indianapolis IN Endodontist Dr. Broady provides faq on Endodontics. 317-846-4980."/>
        <meta name="og:description" content="Indianapolis IN Endodontist Dr. Broady provides faq on Endodontics. 317-846-4980."/>
        <meta property="og:url" content="https://meridianendoindy.com/patient-information/endodontic-faq/"/>
        <meta property="og:image" content={OGImage}/>
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
        <meta name="twitter:url" content="https://meridianendoindy.com/patient-information/endodontic-faq/" />
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content="Endodontic FAQ endodontics pc, Indianapolis IN"/>
        <meta name="twitter:description" content="Indianapolis IN Endodontist Dr. Broady provides faq on Endodontics. 317-846-4980."/>
        <meta name="twitter:image" content={OGImage} />
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="patient-registration-hero-section-bg hero-bg"
        fluid={hero_background}
        backgroundColor={`#ffffff`}
        alt="Endodontics pc"
      >
        <div id="faq-hero-section">
          <Container>
            <div className="col-lg-6 left-content pb-5">
              <h1 className="primary-text hero-heading">
                Endodontics FAQ
              </h1>
              <p className="hero-text mb-3">
                Endodontics is a branch of dentistry recognized by the American Dental Association involving treatment of the pulp (root canal) and surrounding tissues of the tooth.
              </p>
              <Link to="/contact-us/contact-information-office-map"><button className="hero-section-btn"> Schedule a Consultation</button></Link>
            </div>
            <div className="col-lg-6">
              <Img fluid={hero_img} className="hero-img" alt="endodontic faq"/>
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <section id="faded-bg"></section>
      <section id="faq-section-1">
        <Container>
          <div className="col-lg-12">
          {/*  <h3>Have a <span>Question?</span></h3>
            <h4>Look here</h4>
            <form id="form-search">
              <input type="search" />
              <button type="submit"><FaSearch /></button>
            </form> */}
            <p>
              Do you have more questions about Meridian Endodontics and our specialty? Here are some of the more commonly asked questions.
            </p>
            <div className="accordion">
              <details>
                <summary aria-describedby="What is Endodontics?">What is Endodontics?</summary>
                <div className="accordion-content">
                  <p>
                    Endodontics is a branch of dentistry that involves treating the pulp and tissues surrounding your tooth and what you see when looking in the mirror. With teeth, most people focus on how their teeth look from an outside perspective. However, there's more going on under our gums than meets the eye! Most of your dental work will be done beneath this area, which means if anything goes wrong or gets infected with bacteria, root canal therapy becomes necessary.
                  </p>
                </div>
              </details>
              <details>
                <summary aria-describedby="Are X-Rays Dangerous?">Are X-Rays Dangerous?</summary>
                <div className="accordion-content">
                  <p>
                    X-rays are a regular part of dentistry and completely safe. Technological advances have improved safety with digital x-rays, which reduce exposure to radiation by as much as 90% when compared to traditional x-ray equipment. The technology also allows easier access and sharing of dental records for endodontists and other dental professionals. Rather than printing out images, we can email them and upload them to the cloud for storage and easy access.
                  </p>
                </div>
              </details>
              <details>
                <summary aria-describedby="Can I Get an Infection During the Procedure?">Can I Get an Infection During the Procedure?</summary>
                <div className="accordion-content">
                  <p>
                    While infections are possible with any procedure, Meridian Endodontics takes all safety requirements seriously. We follow all OSHA, CDC, and ADA infection control regulations to reduce the risk of infections. Additionally, Meridian Endodontics uses autoclave sterilization and barrier techniques to minimize infection risks.
                  </p>
                </div>
              </details>
              <details>
                <summary aria-describedby="What Technologies Do You Use?">What Technologies Do You Use?</summary>
                <div className="accordion-content">
                  <p>
                    We use the latest technology to make your experience as smooth as possible. Here are some of the tools we use in our office.
                  </p>
                  <h4>Operating Microscopes:</h4>
                  <p>
                    Along with digital x-rays, we have specialized operating microscopes. These use fiber-optic illumination and magnification to help the doctor see inside the small areas of the tooth. If the doctor needs a better idea of what's going on in the tooth to cause inflammation or pain, a tiny video camera on the end of the operating microscope can record images for the endodontist to review more thoroughly.
                  </p>
                  <h4>CBCT or Cone Beam 3D Digital Imaging:</h4>
                  <p>
                    The 3d digital camera allows us to take three-dimensional images of your teeth, jaw, sinuses, and other cranial areas. We use this imaging technology to improve our diagnostic capabilities and enhance treatment and outcomes.
                  </p>
                </div>
              </details>
              <details>
                <summary aria-describedby="What happens after Treatment?">What happens after Treatment?</summary>
                <div className="accordion-content">
                  <p>
                    Aftercare instructions vary by procedure. Once your root canal is complete, our office will refer you to a restorative dentist, which is often your primary dental care provider. They will review the outcome of your endodontics treatment and determine the restoration type necessary to protect your tooth best and prevent further discomfort or tooth loss. While complications following a root canal can occur, these are extremely rare.
                  </p>
                </div>
              </details>
              <p>
                Did you find the answer to your question? If not, please contact our endodontist in Indianapolis at <a href="tel:3178464980">317-846-4980</a>. Our staff will be happy to help.
              </p>
            </div>
          </div>
        </Container>
      </section>
      <section id="faq-section-2">
        <Container>
          <ContactForm />
        </Container>
      </section>
    </Layout>
  )
}

export default FAQPage

export const query = graphql`
  query FAQPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
      }
    },
    hero_background: file(name: { eq: "introduction-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_img: file(name: { eq: "faq-hero-img" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
